@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page__content {
  background-color: white;
  color: black;
  height: 100%;
  padding-top: 35px;
  padding-bottom: 20px;
}

.page .page__content p {
  color: black;
}

.page p {
  color: white;
}

.page-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  color: #FEDD73;
}

.page-title--lg {
  font-size: 32px;
  line-height: 41px;
}

.page-title__sub {
  color: white;
}

.site-logo {
  padding-top: 32px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}

.site-logo__img {
  width: 42px;
}

header {
  height: 106px;
  padding: 0 20px 10px 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.footer {
  height: 100px;
  width: 100%;
  background-color: #40AC49;
  padding-top: 13px;
  padding-bottom: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__label {
  width: 74px;
}

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
}

h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}

h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}

h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: white;
}

p {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 20px;
}
ul {
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 10px;
  margin-top: -20px;
  padding-left: 20px;
}

.container {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.flame {
  flex: 0 0 52px;
  width: 52px;
  height: 70px;
  background-image: url('./images/flame.svg');
  background-size: cover;
  background-position: center;
  text-align: center;
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.backBtn {
  flex: 0 0 10px;
  width: 30px;
  height: 100px;
  background-size: cover;
  background-position: right;
  text-align: right;
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  position: absolute;
  right: 10px;
  top: 5px;
}

.margin-top-lg {
  margin-top: 40px;
}

.margin-bottom-none {
  margin-bottom: 0;
}

.margin-bottom-sm {
  margin-bottom: 10px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.margin-bottom-lg {
  margin-bottom: 40px;
}

.margin-bottom-xlg {
  margin-bottom: 80px;
}

.margin-bottom-xlg-s {
  margin-bottom: 20px;
  left:50px;
  top: -28px;
  width: 89%;
}
.margin-bottom-xsm {
  margin-bottom: 10px;  
}
.margin-bottom-xsm {
  margin-bottom: 15px;
}

.margin-left {
  margin-left: 20px;
}

.progressbar {
  position: relative;
  width: 100%;
  height: 6px;
  background-color: #D5EBD7;
}

.progressbar__progress {
  position: absolute;
  height: 100%;
  left: 0;
  background-color: #40AC49;
}

.formInput {
  position: relative;
}

.formInput p {
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
  padding-right: 40px;
}

.formInput label {
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}

.formInput.formInput--radio label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 30px;
  margin-bottom: 10px;
}

.formInput.formInput--checkbox label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 10px;
  padding: 18px 20px 18px 32px;
  border-radius: 5px;
  border: 1px solid #D5EBD7;
}
.formInput.formInput--checkbox label.weight_1 {
  border: 2px solid #82b54f;
}
.formInput.formInput--checkbox label.weight_2 {
  border: 2px solid #e57536;
}
.formInput.formInput--checkbox label.weight_3 {
  border: 2px solid #e57536;
}
.formInput.formInput--checkbox label.weight_4,
.formInput.formInput--checkbox label.weight_5,
.formInput.formInput--checkbox label.weight_6,
.formInput.formInput--checkbox label.weight_7,
.formInput.formInput--checkbox label.factor-1
{
  border: 2px solid #CD163F;
}


.formInput.formInput--radio input,
.formInput.formInput--checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.formInput.formInput--radio input:checked ~ .checkmark {
  border: 5px solid #e596a8;
}

.formInput.formInput--radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #C8D5DB;
  border-radius: 50%;
}

.formInput.formInput--checkbox .checkmark {
  position: absolute;
  top: 50%;
  left: -10px;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #D5EBD7;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: -10px;
}

.formInput.formInput--checkbox input:checked ~ .checkmark {
  background-color: #40AC49;
  border-color: #40AC49;
}

.formInput.formInput--checkbox .checkmark svg {
  display: none;
}

.formInput.formInput--checkbox input:checked ~ .checkmark svg {
  display: block;
}

.formInput.formInput--text input {
  display: block;
  border: 1px solid #C8D5DB;
  border-radius: 2px;
  width: 100%;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background-color: #40AC49;
  color: white;
}

.btnLightgreen {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background-color:  #EAF9EB;
  color: #40AC49;
}

.btn--transparent {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  border: solid 1px #40AC49;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  color: #40AC49;
}
.btn--link {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  color: #40AC49;
}

table {
  font-family: arial, sans-serif;
  font-size: 13px;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 40px;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.list{
  padding-top: 20px;
}